import React from "react"
import { graphql } from "gatsby"

import ContentModules from "../components/Page/ContentModules"
import Layout from "../components/Layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => {
  const { frontPage } = data

  return (
    <Layout locale={frontPage.node_locale}>
      <SEO
        lang={frontPage.node_locale.substring(0, 2)}
        title={frontPage.pageTitle}
        description={frontPage.metaDescription.metaDescription}
      />
      <ContentModules isHome allModules={frontPage.contentModules} />
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    frontPage: contentfulPage(
      isHomepage: { eq: "Yes" }
      node_locale: { eq: "da-DK" }
    ) {
      ...PageContent
    }
  }
`
